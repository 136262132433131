<template>
    <div class="page">
        <div class="container">
            <div class="row profile">
                <div class="col-sm-3">
                    <div class="profile__sidebar">
                        <div class="profile__name">{{ user.name }}</div>
                        <div class="profile__field">
                            <div class="profile__icon"></div>
                            <div class="profile__value">{{ user.email }}</div>
                        </div>
                        <div class="profile__field">
                            <div class="profile__icon"></div>
                            <div class="profile__value">{{ user.phone }}</div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="profile__content">
                        <div class="profile__title">Моите поръчки</div>
                        <template v-if="orders != null">
                            <template v-if="orders.length > 0">
                                <div v-for="order in orders" class="order">
                                    <div class="order__field">
                                        <div class="order__id">
                                            <router-link
                                                    :to="{ name: 'ProfileOrder', params: { orderId: order.id } }">Поръчка №{{ order.id }}</router-link>
                                        </div>
                                        <div class="order__price">{{ order.price.special }} лв.</div>
                                    </div>
                                    <div class="order__field">
                                        <div class="order__service">Доставка {{ order.slot.text.toLowerCase() }}</div>
                                    </div>
                                    <div class="order__field order__field--border">
                                        <div class="order__address">{{ order.address.street }}<template v-if="order.address.house != null && order.address.house.length > 0">, {{ order.address.house }}</template></div>
                                        <div class="order__status" :class="{'order__status--red': order.status.id === 'CANCELLED'}">{{ order.status.text }}</div>
                                    </div>
                                    <div class="order__field">
                                        <div class="order__date">Създаден на {{ order.created_at | moment("DD MMM YYYY HH:mm") }}</div>
                                        <div class="order__paystatus">
                                            <template v-if="order.pay_status === 'COMPLETED'">Платена</template>
                                            <template v-else-if="order.pay_status === 'RESERVED'">Сумата е блокирана</template>
                                            <template v-else>Не платена</template>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <div v-else class="empty">
                                <div class="empty__icon">🛒</div>
                                <div class="empty__desc">Все още нямате поръчки</div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'ProfileOrders',
        components: {

        },
        data: function () {
            return {

            }
        },
        computed: {
            user() {
                return this.$store.state.auth.user
            },
            orders() {
                return this.$store.state.orders.profileOrders
            }
        },
        methods: {
            ...mapActions([
                'profileOrders',
            ]),
            async getOrders() {
                await  this.profileOrders()
            }
        },
        created() {
            this.getOrders();
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/css/base.scss";

    .page {
        padding-top: 110px;
        min-height: 100vh;
        background: #fafafa;
    }

    .profile {

        &__content {
            margin-bottom: 50px;
        }

        &__sidebar {
            background: rgb(255, 255, 255);
            box-shadow: rgb(93 62 188 / 4%) 0 6px 24px;
            border-radius: 8px;
            padding: 20px 20px 0;
        }

        &__name {
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            padding: 10px 0;
        }

        &__field {
            border-top: 1px solid rgb(243, 240, 254);
            padding: 16px 0;
        }

        &__icon {

        }

        &__value {

        }

        &__title {
            font-weight: 700;
            font-size: 22px;
            margin-bottom: 15px;
            margin-top: 5px;
        }
    }

    .order {
        background: #FFFFFF;
        -webkit-box-shadow: 0 0 20px rgb(0 0 0 / 5%);
        -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 20px rgb(0 0 0 / 5%);
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        border-radius: 12px;
        margin: 0 0 12px;
        padding-top: 10px;
        padding-bottom: 10px;

        &__field {
            padding: 2px 0;
            margin: 0 15px;

            &--border {
                border-top: 1px solid #f4f4f4;
                padding-top: 8px;
                margin-top: 8px;
            }
        }

        &__id {
            display: inline-block;
            font-size: 18px;
            font-weight: 700;
        }

        &__date {
            display: inline-block;
            font-size: 12px;
            opacity: 0.6;
        }

        &__address {
            display: inline-block;
        }

        &__service {
            display: inline-block;
        }

        &__status {
            display: inline-block;
            float: right;
            font-weight: 700;

            &--red {
                color: #FF3B30;
            }
        }

        &__price {
            display: inline-block;
            float: right;
            font-size: 18px;
            font-weight: 700;
            color: $tilda;
        }

        &__paystatus {
            display: inline-block;
            float: right;
            font-weight: 700;
            font-size: 12px;
            opacity: 0.6;
        }
    }

    .empty {
        background: #FFFFFF;
        -webkit-box-shadow: 0 0 20px rgb(0 0 0 / 5%);
        -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 20px rgb(0 0 0 / 5%);
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        border-radius: 12px;
        margin: 0 0 12px;
        text-align: center;
        padding: 50px;

        &__icon {
            margin-bottom: 30px;
            font-size: 50px;
            margin-top: 20px;
        }

        &__desc {
            font-size: 20px;
            opacity: 0.6;
            margin-top: 40px;
        }
    }

</style>